<template>
  <div class="container">
    <GeneralTable
      ref="GeneralTable"
      :column="column" tableName="partsRetrievalBackstageList"
      :option="option"
      :dataList="dataList"
      :totalNum="totalNum" @search-change="getList"
      @export-data="exportData"
      @add-change="(operateTitle = '添加'), (editData = {}), (isAddVisible = true)"
      @import-click="isImport = true"
    >
      <template slot="deviceType">
          <el-select filterable clearable v-model="ruleForm.deviceType" placeholder="请选择" @change="selectMachineryProductType">
            <el-option v-for="item in machineryProductTypeList"
                    :key="item.dictType"
                    :label="item.dictName"
                    :value="item.dictName"></el-option>
          </el-select>
      </template>
      <template slot="deviceCategory">
                <el-select class="row-selectAddress"
                  v-model="ruleForm.deviceCategory"
                  filterable clearable
                  placeholder="设备种类">
                  <el-option v-for="item in machineryEquipmentTypeList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictLabel"></el-option>
                </el-select>
      </template>
      <template slot="enableStatus" slot-scope="{item}">
        <el-tag type="success" v-if="item.enableStatus">启用</el-tag>
<el-tag type="danger" v-else>禁用</el-tag>
      </template>
      <template slot="partImage"
        slot-scope="{item}">
          <el-image
          style="width: 40px; height: 40px"
          :src="item.partImage || require('@/assets/images/partsRetrieval/parts-defaultIcon.png')"
          :preview-src-list="[item.partImage]"
        >
        </el-image>
      </template>
      <template slot="operate"
        slot-scope="{item}">
        <el-button class="polecy-button"
          @click.native.prevent="goDetail(item)"
          type="text"
          size="small">查看详情</el-button>
        <el-button class="polecy-button"
          @click.native.prevent="editDetail(item)"
          type="text"
          size="small">编辑</el-button>
        <el-button class="polecy-button"
          @click.native.prevent="handleDel(item)"
          type="text"
          size="small">删除</el-button>
      </template>
      <template slot="slot-view">
        <div class="slot-list">
          <div class="item" v-for="(item, index) in dataList" :key="index">
            <el-image class="img" :src="item.partImage || require('@/assets/images/partsRetrieval/parts-defaultIcon.png')"></el-image>
            <div class="title">
              {{ item.partName }}
            </div>
            <div class="info">
              <div class="modelNum">{{ item.partModel }}</div>
              <div class="price">{{ item.supplierQuotationMin }}-{{ item.supplierQuotationMax }}</div>
            </div>
            <div class="tags">
              <div class="tag">{{ item.deviceCategory }}</div>
              <div class="tag">{{ item.partBrand }}</div>
            </div>
          </div>
        </div>
      </template>
    </GeneralTable>
    <partsRetrievalImport v-model="isImport"
      @changeList="changeGetList" />
    <partsRetrievalDialog v-model="isAddVisible" :editId="editData.id" @changeList="changeGetList" />
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import partsRetrievalImport from "@/views/PartsRetrieval/components/partsRetrievalImport.vue";
import partsRetrievalDialog from "@/views/PartsRetrieval/components/partsRetrievalDialog.vue";
import { removeBatchZeroParts, getKindInfoAndNumber, getZeroPartsPage, getBrandInfoPageList } from "@/api/basicData"
import {
  getDictList, //获取产品列表
  getDictType, //获取设备列表
} from "@/api/getManagementData.js";
import Template from '../RiskDecrement/riskHomeManagement/components/template.vue';
import dayjs from 'dayjs';
export default {
  name: "partsRetrievalBackstageList",
  components: { GeneralTable, partsRetrievalImport, partsRetrievalDialog },
  data() {
    Template
    return {
      dataList: [],
      totalNum: 0,
      option: {
          isAdd: true,
        isRefresh: true, // 刷新 @search-change
        isSearch: true,
        isImport: true, // 导入 @import-click
        isSelection: false,
        pageSize: 10,
        searchList: [
          {
            label: "配件品号",
            prop: "partNumber",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "配件名称",
            prop: "partName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "配件型号",
            prop: "partModel",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "配件品牌",
            prop: "partBrand",
            formType: "select",
            filterable: true,
            selectList: [],
            placeholder: "请选择",
          },
          {
            label: "设备类型",
            prop: "deviceType",
            formType: "range-input",
            filterable: true,
            selectList: [],
            clearable: true,
            placeholder: "请选择",
          },
          {
            label: "设备种类",
            prop: "deviceCategory",
            formType: "range-input",
            filterable: true,
            selectList: [],
            clearable: true,
            placeholder: "请选择",
          },
          {
            label: "添加人",
            prop: "createUserName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "状态",
            prop: "enableStatus",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: "0", text: "禁用" },
              { value: "1", text: "启用" },
            ],
            clearable: true,
            placeholder: "请选择",
          },
          {
            label: "添加时间",
            prop: "createTime",
            format: "yyyy.MM.dd",
            formType: "daterange",
            clearable: true,
            prop: ["createTimeStart", "createTimeEnd"],
          },
          {
            label: "更新时间",
            prop: "updateTime",
            format: "yyyy.MM.dd",
            formType: "daterange",
            clearable: true,
            prop: ["updateTimeStart", "updateTimeEnd"],
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "配件品号",
          prop: "partNumber",
          isShow: true,
        },
        {
          label: "配件名称",
          prop: "partName",
          isShow: true,
        },
        {
          label: "配件型号",
          prop: "partModel",
          isShow: true,
        },
        {
          label: "配件品牌",
          prop: "partBrand",
          isShow: true,
        },
        {
          label: "配件图片",
          prop: "partImage",
          isShow: true,
          isSlot: true,
        },
        {
          label: "设备类型",
          prop: "deviceType",
          isShow: true,
        },
        {
          label: "设备种类",
          prop: "deviceCategory",
          isShow: true,
        },
        {
          label: "设备型号",
          prop: "deviceModelNumber",
          isShow: true,
        },
        {
          label: "添加人",
          prop: "createUserName",
          isShow: true,
        },
        {
          label: "添加时间",
          prop: "createTime",
          isShow: true,
        },
        {
          label: "状态",
          prop: "enableStatus",
          isShow: true,
          isSlot: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 180,
          fixed: "right",
          isSlot: true,
        },
      ],
      typesOfConstructionMachineryList: [],
      brandInfoPageList: [],
      partBrandList: [],
      deviceTypeList: [],
      isExpansion: false,
      isImport: false,
      isAddVisible: false,
      editData: {},
      oldSearchData: {},
      operateTitle: '添加',
      machineryEquipmentTypeList: [],
      machineryProductTypeList: [],
      ruleForm: {
        deviceType: '',
        deviceCategory: ''
      }
    };
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    init() {
      getKindInfoAndNumber({
        index: 1,
        size: 9999,
      }).then(res=>{
        this.typesOfConstructionMachineryList = res.data.typesOfConstructionMachineryList || [];
        // this.brandInfoPageList = res.data.constructionMachineryBrandList || [];
      });
      getBrandInfoPageList({
        pageNum: 1,
        pageSize: 9999,
        current: 1,
        size: 9999
      }).then(res=>{
        let nameList = res.data.records.map(val=> val.brandName);
        this.brandInfoPageList = res.data.records.filter((item, index) => nameList.indexOf(item.brandName) === index);
        let ind = this.option.searchList.findIndex(
          (val) => val.label === "配件品牌"
        );
        this.option.searchList[ind].selectList = res.data.records.map(
          (val) => {
            return {
              text: val.brandName,
              value: val.brandName,
            };
          }
        );
      });
      
      getDictList({ type: '1' }).then(res => {
        if (res.flag === 1) {
          this.machineryProductTypeList = res.rows;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    getList(v, pageNum, pageSize, n, type){
      if (type == 'reset') {
        this.ruleForm = {
          deviceType: '',
          deviceCategory: ''
        }
      }
      if (v.createTimeStart && v.createTimeStart.split(' ').length === 1) {
        v.createTimeStart = dayjs(v.createTimeStart).format('YYYY-MM-DD') + ' 00:00:00';
        v.createTimeEnd = dayjs(v.createTimeEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      if (v.updateTimeStart && v.updateTimeStart.split(' ').length === 1) {
        v.updateTimeStart = dayjs(v.updateTimeStart).format('YYYY-MM-DD') + ' 00:00:00';
        v.updateTimeEnd = dayjs(v.updateTimeEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      // , deviceType: this.deviceTypeList.toString()
      // , partBrand: this.partBrandList.toString()
      this.oldSearchData = Object.assign({...this.ruleForm} ,{...v, index: pageNum, size:pageSize, fuzzyLookupField: v.keyword})
      getZeroPartsPage(this.oldSearchData).then(res=>{
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      })
    },
    handleClassification(e, type) {
      // this[type].includes(e) ? this[type].splice(this[type].findIndex ,1) : this[type].push(e);
      this[type].includes(e) ? this[type] = [] : this[type] = [e]
      this.changeGetList();
    },
    changeGetList() {
      this.$refs.GeneralTable.getList();
    },
    goDetail(e) {
      console.log('详情');
      this.$router.push({
        path: '/routerPartsRetrieval/partsRetrievalDetails?id='+e.id
      })
    },
    exportData(e) {
      exportPolicy({
        ...this.oldSearchData,
        category: this.$route.meta.policyType,
        ids: e.map((val) => val.id).toString(),
      });
    },
    handleDel(e) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          removeBatchZeroParts({
            ids: e.id
          }).then(res=>{
            this.changeGetList();
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          })
        })
    },
    editDetail(e) {
      this.editData = JSON.parse(JSON.stringify(e));
      this.isAddVisible = true;
    },
    // 获取设备类型列表
    getMachineryEquipmentTypeList() {
      getDictType({ dictType: this.machineryProductTypeList.find(val=> val.dictName == this.ruleForm.deviceType).dictType  }).then(res => {
        if (res.flag === 1) {
          this.machineryEquipmentTypeList = res.obj;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    // 选择产品类型
    selectMachineryProductType() {
      this.ruleForm.deviceCategory = '';
      this.getMachineryEquipmentTypeList();
    },
  },
};
</script>
<style lang="scss" scoped>
.search-filter {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  .product,
  .brand {
    display: flex;
    .label {
      width: 100px;
      height: 28px;
      line-height: 28px;
      white-space: nowrap;
    }
    .list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .item {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        padding: 0 12px;
        border: 1px solid #4278C9;
        margin: 0 15px 15px 0;
        cursor: pointer;
        border-radius: 4px;
        // &:hover {
        //   color: #4278c9;
        //   background-color: #eff6ff;
        // }
      }
      .check-item {
        color: #FFFFFF;
        background-color: #4278c9;
        border: 1px solid #4278C9;
      }
    }
  }
  .expansion-box {
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    cursor: pointer;
    font-size: 14px;
    color: #0080FF;
    text-align: center;
  }
}
.enableStatus1 {

}
.enableStatus2 {

}
.slot-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 250px;
    padding: 10px;
    border: 1px solid #dee3e6;
    border-radius: 6px;
    margin: 0 15px 15px 0;
    .img {
      width: 230px;
      height: 230px;
      margin-bottom: 8px;
    }
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .modelNum {
        font-size: 14px;
      }
      .price {
        font-size: 18px;
        color: #fda835;
        font-weight: bold;
      }
    }
    .tags {
      display: flex;
      align-items: center;
      .tag {
        margin-right: 8px;
        padding: 5px 8px;
        background-color: #f2f7fd;
        color: #3470ff;
        border-radius: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
